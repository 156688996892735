<template>
    <div id="nav-container" class="nav-container fixed-top" >
            <nav id="nav" class="navbar navbar-expand-lg" :style="inlineStyle">
                <div class="container-fluid">
                    <button class="navbar-toggler ml-auto collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" 
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <font-awesome-layers class="fa-lg iconStack">
                            <font-awesome-icon icon="bars" class="barsIcon spinIcon"/>
                            <font-awesome-icon icon="times" class="timesIcon spinIcon"/>
                        </font-awesome-layers>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarNav">
                        <ul class="navbar-nav ml-auto">
                            <li class="nav-item">
                                <router-link class="nav-link" aria-current="page" to="/" @click="switchImage('haupt.jpg')">HOME</router-link>
                            </li>

                            <li class="nav-item">
                                <router-link class="nav-link" to="/Fahrzeuge" @click="switchImage('haupt.jpg')">Fahrzeuge</router-link>
                            </li>

                            <li class="nav-item">
                                <router-link class="nav-link" to="/Kinderfeuerwehr" @click="switchImage('kinder2.jpg')">Kinderfeuerwehr</router-link>
                            </li>

                            <li class="nav-item">
                                <router-link class="nav-link" to="/Jugendfeuerwehr" @click="switchImage('jugend.jpg')">Jugendfeuerwehr</router-link>
                            </li>

                            <li class="nav-item">
                                <router-link class="nav-link" to="/Einsatzabteilung" @click="switchImage('aktiv.jpg')">Einsatzabteilung</router-link>
                            </li>

                            <li class="nav-item">
                                <router-link class="nav-link" to="/Altersabteilung" @click="switchImage('alters.jpg')">Altersabteilung</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        <Searchbar></Searchbar>
    </div>
</template>

<script>
//import router from '@/router';
import Searchbar from './Searchbar.vue';

export default {
    name: "Navigation",
    components: {
        Searchbar: Searchbar,
    },
    data() {
      return {
          selectedImage: "haupt.jpg",
      }
    },
    computed: {
        bgImage () {
        return require('./images/' + this.selectedImage)
        },
         inlineStyle () {
            return {
                backgroundImage: `url(${this.bgImage})` 
            }
        }
    },
    methods: {
        switchImage(name) {
            return this.selectedImage = name;
        },
    }
}

</script>

<style>
.nav-container .navbar {
   /* background: url(../assets/images/kinder.jpg) no-repeat;*/
    background-size: cover;
}

.nav-container {
    z-index: 3000;
}

.nav-container .container,
.nav-container .container-fluid {
    transition: all 0.5s ease-in-out;
    padding: 0;
}

.nav-container .navbar {
    background-color: #ffffff;
    color: #6C6C6C;
    font-weight: 600;
    min-height: var(--nav-height);
    position: relative;
    transition: min-height 0.5s ease-in-out;
    padding: 0 15px 0 10px;
}

.nav-container .navbar .navbar-brand {
    height: calc( var(--nav-height) - (var(--nav-padding)));
    width: calc( ((var(--nav-height) - (var(--nav-padding) * 2)) / 0.117));
    position: absolute;
    transition: all 0.5s ease-in-out;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.nav-container .navbar .navbar-brand img {
    display: block;
    height: 75%;
    margin: 0 16px;
    height: 100%;
}
.navbar-toggler:focus {
    box-shadow: 0 0 0 0 !important;
}
.nav-container .navbar .navbar-toggler {
    margin-top: 14px;
    margin-bottom: 14px;
    transition: margin-top 0.5s ease-in-out;
    padding: 0;
}

.nav-container .navbar .navbar-toggler .barsIcon,
.nav-container .navbar .navbar-toggler.collapsed .timesIcon {
    opacity: 0;
    color: #ffffff;
}

.nav-container .navbar .navbar-toggler .timesIcon,
.nav-container .navbar .navbar-toggler.collapsed .barsIcon {
    opacity: 1;
    color: #ffffff;
}

.nav-container .navbar .navbar-toggler .iconStack {
    width: 44px;
    height: 30px;
    line-height: 30px;
}

.nav-container .navbar .navbar-toggler .spinIcon {
    transition: all 0.5s ease-in-out;
}

.nav-container .navbar .navbar-toggler.collapsed .spinIcon {
    transform: rotate(180deg);
}

.nav-container .navbar .nav-item {
    margin-left: 30px;
    margin-right: 10px;
}

.nav-container .navbar .nav-item:first-child {
    margin-left: 0;
}

.nav-container .navbar .nav-item .nav-link {
    color: #ffffff;
    text-align: right;
    padding: 0;
    text-shadow: -1px -1px 0 black,
    1px -1px 0 black,
    -1px 1px 0 black,
    1px 1px 0 black;
}

.nav-container .navbar .nav-link:hover,
.nav-container .navbar .nav-link:focus,
.nav-container .navbar .nav-item .nav-link.active {
    color: #f57b7b;
    text-decoration: underline;
}

.nav-container .navbar .account {
    line-height: 18px;
    min-width: 165px;
    float: right;
    padding: 8px 0px 8px 12px;
    transition: padding-right 0.5s ease-in-out;
    width: calc( (100% - var(--container-width-xl)) / 2);
}

.nav-container .navbar .account .portrait {
    margin-right: 10px;
    line-height: 36px;
    font-size: 32px;
}

.nav-container .navbar .account .name {
    display: block;
    text-align: left;
    font-size: 0.85rem;
    height: 36px;
    float: right;
    max-width: 100px;
}

.nav-container .navbar .account .name .username {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.nav-container .navbar .account .name .hello {
    font-weight: 300;
}

::after, ::before {
    box-sizing: border-box;
}
.ml-auto, .mx-auto {
    margin-left: auto!important;
}

@media (max-width: 1420px) {

    /* resizing navbar brand */
    .nav-container .navbar .navbar-brand {
        height: calc( var(--nav-height) - (var(--nav-padding)));
        width: calc( ((var(--nav-height) - (var(--nav-padding) * 2.65)) / 0.117));
    }

    /* collapsing menu-sidebar */
    .menu-sidebar.active {
        left: -80px;
    }
    .menu-sidebar.active .menu-link a {
        width: 50px;
    }
    .searchbar-wrapper .toggle-menu-sidebar {
        display: none;
    }
    .navbar-expand-lg .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row;
    }
}

/* regular bootstrap breakpoint */
@media (max-width: 1199.5px) {

    /* navbar sizing */
    .main-content {
        margin-top: calc( var(--nav-height-slim) + var(--searchbar-height));
        min-height: calc( 100vh - var(--nav-height-slim) - var(--searchbar-height));
    }
    .nav-container .navbar,
    .nav-container.slim .navbar {
        min-height: var(--nav-height-slim);
    }
    .nav-container .navbar .navbar-brand,
    .nav-container.slim .navbar .navbar-brand {
        height: calc( var(--nav-height-slim) - (var(--nav-padding-slim)));
        width: calc((var(--nav-height-slim) - (var(--nav-padding-slim) * 2)) / 0.117);
    }
    .nav-container .navbar .account,
    .nav-container.slim .navbar .account  {
        min-width: 140px;
        width: unset;
    }

    /* smaller padding to content, if navbar is smaller */
    .main-content .container.padding-to-header,
    .nav-container.slim + .main-content.padding-to-header {
        padding-top: 40px;
    }

}


/* in-between step for navbar brand */
@media (max-width: 1060px) {

    /* resizing navbar brand */
    .nav-container .navbar .navbar-brand,
    .nav-container.slim .navbar .navbar-brand {
        height: calc( var(--nav-height-slim) - (var(--nav-padding-slim)));
        width: calc((var(--nav-height-slim) - (var(--nav-padding-slim) * 2.7)) / 0.117);
    }

}


/* regular bootstrap breakpoint */
@media (max-width: 991.5px) {

    .navbar-expand-lg .navbar-nav {
        -ms-flex-direction: column;
        flex-direction: column;
    }

    /* move sidebar to the top */
    .menu-sidebar,
    .menu-sidebar.active {
        left: 0px;
        top: -80px;
        width: 100%;
        height: 90px;
    }
    .menu-sidebar .menu-link {
        float: left;
        width: 25%;
        height: 100%;
    }
    .menu-sidebar .menu-link a,
    .menu-sidebar.active .menu-link a {
        width: 100%;
        height: 50px;
    }
    .menu-sidebar:hover,
    .menu-sidebar:focus {
        top: 0px;
    }

    .main-content,
    .nav-container.slim + .main-content {
        margin-top: calc( var(--nav-height-slim) + var(--searchbar-height) + 10px);
        min-height: calc( 100vh - var(--nav-height-slim) - var(--searchbar-height) - 10px);
    }

    /* add margin to account for collapsible navbar */
    .nav-container .navbar .account {
        margin-bottom: 12px;
    }
}

</style>
