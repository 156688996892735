import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'


//fontAwesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faHome, faSignOutAlt, faSignInAlt, faTrashAlt,
    faTasks, faPlusCircle, faSort, faSortUp, faSortDown, faSearch, faCheck, faExclamationTriangle,
    faFilter, faUserCircle, faBars, faTimes , faPhone, faFax, faEnvelope, faInfo, faAngleDown, faAngleUp} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers } from "@fortawesome/vue-fontawesome";

library.add(faHome, faSignOutAlt, faSignInAlt, faTrashAlt, faTasks, faPlusCircle,
    faSort,faSortUp, faSortDown, faSearch, faCheck, faExclamationTriangle, faFilter, faUserCircle, faBars, faTimes,
    faPhone, faFax, faEnvelope, faInfo, faAngleDown, faAngleUp);

const app = createApp(App)
    .use(router)
    .component("font-awesome-layers", FontAwesomeLayers)
    .component("font-awesome-icon", FontAwesomeIcon);

    app.mount('#app');